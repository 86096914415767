














































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Particles from "@/components/Particles.vue";
import { namespace } from "vuex-class";
import Notifier from "@/helpers/notifier";
import Logo from "@/components/Logo.vue";
import agent from "@/helpers/agent";

const app = namespace("app");

@Component({ components: { Logo, Particles } })
export default class Login extends Vue {
  @app.Action
  login: any;
  @app.Action
  private logout: any;

  rememberMe = false;
  isPwd = true;
  name = "";
  password = "";
  passwordInputType = "Password";
  logo = `@assets/logo.png`;
  loading = false;
  async onSubmit() {
    // implement login
    this.loading = true;
    // this.login({ username: this.name, password: this.password })
    //   .then(() => [
    //     Notifier.showPositive("You have succesfully logged in"),
    //     this.$router.push("/usage-statistics"),
    //   ])
    //   .catch(() => [Notifier.showError("bad username/password combination")])
    //   .finally(() => [(this.loading = false)]);

    try {
      await this.login({ username: this.name, password: this.password });
      await this.$router.push({ name: "UsageStatistics" });
      Notifier.showPositive("You have succesfully logged in");
    } catch (error) {
      Notifier.showError("bad username/password combination" + error);
      console.log(error)
    } finally {
      this.loading = false;
    }
  }
  onReset() {
    this.name = "";
    this.password = "";
    this.rememberMe = false;
  }
  created() {
    this.logout();
  }
}
